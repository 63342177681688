import { Accordion, Countdown, CtaTeaser, CtaTeaserImage, FeatureGrid, IconGrid, NewsletterPopUp, TextImageRight, TrustpilotCompanyReviews } from "../../components/slices/generic";
import { FeaturedProducts, Hero, HorizontalScroll, MediaSlider, Testimonials, TextVideo } from "../../components/slices/home";
import { DistributorsList } from "../../components/slices/im-handel-page";
import HeroReferrals from "../../components/slices/landing-page/hero-referrals/hero-referrals";
import { ComparisonGrid } from "../../components/slices/product-page";


export const components = {
    hero: Hero,
    hero_referrals: HeroReferrals,
    media_slider: MediaSlider,
    horizontal_scroll: HorizontalScroll,
    text_image_right: TextImageRight,
    comparison_grid: ComparisonGrid,
    feature_grid: FeatureGrid,
    distributors_list: DistributorsList,
    testimonials: Testimonials,
    accordion: Accordion,
    icon_grid: IconGrid,
    featured_products: FeaturedProducts,
    text___video: TextVideo,
    cta_teaser_image: CtaTeaserImage,
    cta_teaser: CtaTeaser,
    trustpilot_company_reviews: TrustpilotCompanyReviews,
    countdown: Countdown,
    'email_pop-up': NewsletterPopUp,
}
