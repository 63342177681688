import { langToCountry, langToLanguage } from "@utils";
import Client from "../../../../utils/playbrush-client/client";
import { Cart, CheckDiscountReturn } from "../../../../utils/playbrush-client/types";

export const addDiscountCode = (cart: Cart, lang: string, currency: string, couponCode: string): Promise<Cart | false> => {
    return Client.checkDiscount({ couponCode }).then(data => {
        const { valid } = (data || {}) as CheckDiscountReturn;

        if (valid) {
            let payload: { lineId: string, quantity: number }[] = cart.lineItems.map((lineItem) => ({
                lineId: lineItem.lineId,
                quantity: lineItem.quantity,
                frontendLineData: JSON.stringify(lineItem.frontendLineData),
            }));

            const frontendData = {
                ...(cart.frontendData as unknown) as Record<string, unknown>,
                thankYouPage: ((cart.frontendData as unknown) as { thankYouPage: string })?.thankYouPage || '',
            };

            return Client.addDiscount({
                itemList: payload || [],
                currency: cart.currency,
                country: langToCountry(lang),
                language: langToLanguage(lang),
                cartId: cart.cartId,
                couponCode,
                // @ts-ignore
                frontendData: JSON.stringify(frontendData),
            })
        } else {
            return new Promise((res) => res(false));
        }
    })
}